<template>
  <div style="margin-left:30px;margin-right:30px;">
    <h2>
      <center>Shipping Partners</center>
    </h2>
    <br /><br />
    <b-card no-body class="mb-0" style="background-color:#ebe9f1;">
      <div class="m-2">
        <b-row style="margin-bottom:5px;">
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">

          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">

              <b-button variant="primary" @click="addShippingPartner()">
                <span class="text-nowrap">Add Shipping Partner</span>
              </b-button>
              <b-button variant="primary" style="margin-left:10px;" v-if="selectedItem != 'shippingPartners'"
                @click="onClickShippingPrice('newShippingPrice', '{}')">
                <span class="text-nowrap">Add Shipping Price</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" class="d-flex  justify-content-start mb-1 mb-md-0"
            style="border-right: 1px solid #c9c9c9;">
            <div>
              <h6>Shipping Partners List</h6>
              <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" split :text="selectedItem" v-model="selectedItem"
                variant="primary" right>
                <b-dropdown-item disabled>
                  Select ShippingPartner
                </b-dropdown-item>
                <b-dropdown-item v-for="shippingPartner in allShippingPartners" :key="shippingPartner.id"
                  :value="shippingPartner.shippingpartners" @click="onSelectedShippingPartner(shippingPartner)">
                  {{ shippingPartner.shippingpartners }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
          <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <div>
              <b-table sticky-header="400px" :hover="true" responsive :items="selectedPartnerPrices"
                :fields="tableColumns">
                <template #cell(name)="data">
                  <div class="text-nowrap">
                    {{ data.item.itemType }}
                  </div>
                </template>
                <template #cell(name)="data">
                  <div class="text-nowrap">
                    {{ data.item.weightRangeMin }}
                  </div>
                </template>
                <template #cell(name)="data">
                  <div class="text-nowrap">
                    {{ data.item.weightRangeMax }}
                  </div>
                </template>

                <template #cell(country)="data">
                  <div class="text-nowrap">
                    {{ getCountryName(data.item.country) }}
                  </div>
                </template>

                <template #cell(price)="data">
                  <div class="form-outline">
                    <input type="number" id="typeNumber" class="form-control" v-model="data.item.price" />
                    <!-- <label class="form-label" for="typeNumber">Number input</label> -->
                  </div>


                </template>
                <template #cell(actions)="data">
                  <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>
                    <b-dropdown-item @click="onClickShippingPrice('editPrice', data.item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="deleteShippingPrice({ id: data.item.id, shippingPartnerId: data.item.shippingpartnerId })">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>

                </template>

              </b-table>
            </div>

          </b-col>
        </b-row>
      </div>
      <div class="m-2">
        <b-modal ref="shippingPrice" :title="shippingPriceTitle" v-model="showshippingPrice">
          <form ref="form1">
            <b-form-group id="input-group-item-type" label="item Type" label-for="item-Type"
              invalid-feedback="item type is required" :state="itemTypeState">
              <!-- {{ shippingPriceObj.itemType }} -->
              Item type:
              <select v-model="shippingPriceObj.itemType">
                <option disabled value="">Please select one</option>

                <option value="nondocument">Non-Document</option>
                <option value="document">Document</option>
                <option value="medicineandsuplements">Medicine And Suplements</option>
              </select>

              <b-form-input class="forminput" disabled id="item-type" v-model="shippingPriceObj.itemType" autofocus
                :state="itemTypeState" aria-describedby="item-Type" placeholder="Select item type from dropdown"
                required style="margin-top:10px;" />
                Enter Item Type
              <b-form-invalid-feedback id="item-Type" v-if="shippingPriceObj.itemType == ''">
                
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
  id="input-group-weight-Range-Min"
  label="weightRangeMin"
  label-for="weightRangeMin"
  invalid-feedback="Weight range is invalid"
  :state="isWeightValid && !weightRangeError ? null : false"
>
  <b-form-input
    class="forminput"
    id="weightRangeMin"
    v-model="shippingPriceObj.weightRangeMin"
    :state="weightRangeMinState"
    type="number"
    step="0.001"
    aria-describedby="input-weight-range-min"
    trim
    style="margin-top:10px;"
  />
  Enter the min value of Weight Range
</b-form-group>

<b-form-group
  id="input-group-weight-Range-Max"
  label="weightRangeMax"
  label-for="weightRangeMax"
  invalid-feedback="Weight range is invalid"
  :state="isWeightValid && !weightRangeError ? null : false"
>
  <b-form-input
    class="forminput"
    id="weightRangeMax"
    v-model="shippingPriceObj.weightRangeMax"
    :state="weightRangeMaxState"
    type="number"
    step="0.001"
    min="0.000"
    aria-describedby="weightRangeMax"
    style="margin-top:10px;"/>
    
  Enter the max value of Weight Range
  <br>
  <b-form-invalid-feedback id="input-weight-range-max" v-if="!isWeightValid && weightRangeError" class="errmsg">
    Maximum weight should be greater than minimum weight
  </b-form-invalid-feedback>
</b-form-group>


            <b-form-group id="input-group-price" label="price" label-for="price" invalid-feedback="price is required">
              <b-form-input class="forminput" id="price" v-model="shippingPriceObj.price" :state="priceState"
                type="number" step="0.001" min="0.000" aria-describedby="input-price" trim style="margin-top:10px;" />
                Enter price value
              <b-form-invalid-feedback id="input-price" v-if="shippingPriceObj.price == 0">
                
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-country" label="country" label-for="country"
              invalid-feedback="country is required" :state="countryState">

              <!-- Country -->
              <b-form-input class="forminput" id="country" v-model="shippingPriceObj.country" autofocus value="us"
                :state="countryState" aria-describedby="country" placeholder="country" required disabled
                style="margin:10px 0;" />
              <b-form-invalid-feedback id="country" v-if="shippingPriceObj.country == ''">
                <!-- Enter country Name -->
              </b-form-invalid-feedback>
              <!-- {{ shippingPriceObj.country }} -->
              Country:
              <!-- <select v-model="shippingPriceObj.country">
                <option disabled value="">Please select one</option>
                <option value="us" selected>US</option>
              </select> -->
              <select name="country" v-model="shippingPriceObj.country" class="form-control" id="country">
                <option style="color:black;" value="0" label="Select a country ... " selected="selected">Select a
                  country ... </option>
                    <option v-for="ctry in enabledCountries" style="color: black;" :value="ctry.countryCode" :label="ctry.countryName">{{ ctry.countryName }}</option>
              </select>

            </b-form-group>
            <div class="mb-3" style="margin-top:10px;text-align:center;">
              <button class="btn btn-primary" type="button" @click="addOrUpdateShippingPrice()" :disabled="!isWeightValid">Save</button>
            </div>
          </form>

        </b-modal>
      </div>
      <div class="m-2" v-if="showAddPartner">
        <b-modal ref="addNewPartner" title="Add New Shipping Partner" v-model="showAddPartner">
          <form ref="form">
            <b-form-group id="input-group-shippingPartner" label="shipping partner" label-for="shipping partner"
              invalid-feedback="shipping partner name is required">
              <b-form-input class="forminput" id="new-shiiping-partner" v-model="shippingpartners" autofocus
                :state="newShippingPartner" aria-describedby="input-shipping-partner"
                placeholder="Shipping Partner Name" required style="margin-bottom:10px;" />
              <!-- <b-form-invalid-feedback id="new-shiiping-partner" v-if="shippingPartner==''">
            Enter Shipping PartnerName
          </b-form-invalid-feedback> -->

            </b-form-group>
            <b-form-group id="input-group-trackingURL" label="tracking Url" label-for="tracking Url"
              invalid-feedback="tracking Url is required">
              <b-form-input class="forminput" id="tracking-Url" v-model="trackingURL" autofocus :state="newTrackingUrl"
                aria-describedby="input-trackingURL" placeholder="tracking URL" required style="margin-bottom:10px;" />
              <!-- <b-form-invalid-feedback id="tracking-Url" v-if="trackingURL==''">
            Enter Tracking Url
          </b-form-invalid-feedback> -->

            </b-form-group>

            <div class="mb-3">
              <button class="btn btn-primary" type="button" @click="newPartner()">Confirm</button>
            </div>
          </form>

        </b-modal>
      </div>

    </b-card>
  </div>
</template>
<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BPopover, BCardText
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { mapMutations, mapActions, mapState } from "vuex";
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import isoCountries from 'i18n-iso-countries';

export default {
  mounted: async function () {
    isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    this.loadAllShippingPartners()
    await this.getEnabledCountries();
  },
  watch: {
  'shippingPriceObj.weightRangeMin': function (newMinWeight, oldMinWeight) {
    this.validateWeightRange();
  },
  'shippingPriceObj.weightRangeMax': function (newMaxWeight, oldMaxWeight) {
    this.validateWeightRange();
  },
},

  computed: {

    ...mapState({
      allShippingPartners: (state) => {
        console.log("allShippingPartners " + JSON.stringify(state.shippingPartner.allShippingPartners))
        return state.shippingPartner.allShippingPartners
      },
      selectedPartnerPrices: (state) => {
        return state.shippingPartner.selectedPartnerPrices
      },
      enabledCountries: state => {
        return state.countriesSelection.enabledCountries
      }

    }),
    newShippingPartner() {
      return this.shippingpartners != '' ? true : false
    },
    newTrackingUrl() {
      return this.trackingURL != '' ? true : false
    },
    itemTypeState() {
      return this.shippingPriceObj.itemType != '' ? true : false
    },
    weightRangeMaxState() {
      return this.shippingPriceObj.weightRangeMax > 0 ? true : false
      
    },
    weightRangeMinState() {
      return this.shippingPriceObj.weightRangeMin > -1 ? true : false
    },
    priceState() {
      return this.shippingPriceObj.price > 0 ? true : false
    },
    countryState() {
      return this.shippingPriceObj.country != '' ? true : false
    },
    isWeightValid() {
    return this.shippingPriceObj.weightRangeMax > this.shippingPriceObj.weightRangeMin;
  },

  },
  methods: {
    ...mapMutations({
      setPrices: 'shippingPartner/SET_Prices'
    }),
    ...mapActions({
      loadAllShippingPartners: 'shippingPartner/fetchAllShippingPartners',
      priceUpdate: 'shippingPartner/updatePrice',
      addNewShippingPartner: 'shippingPartner/addNewShippingPartner',
      addNewShippingPrice: 'shippingPartner/addNewShippingPrice',
      deleteShippingPrice: 'shippingPartner/removeShippingPrice',
      getEnabledCountries: 'countriesSelection/getEnabledCountries',

    }),
    async onSelectedShippingPartner(shippingPartner) {
      var comp = this;
      console.log("selected shipping partner " + JSON.stringify(shippingPartner));
      comp.selectedItem = shippingPartner.shippingpartners;
      comp.shippingPartnerId = shippingPartner.id;
      console.log("selectedItem" + comp.selectedItem);
      // var payload = {
      //
      // }
      // console.log("onSelectedShippingPartner payload=="+JSON.stringify(payload))
      comp.setPrices({ shippingPrices: shippingPartner.shippingPrice });

    },
    async updatePrice(priceInfo) {
      //this.priceUpdate(priceInfo);
      this.toast('b-toaster-top-right')
    },
    toast(toaster, append = false) {
      this.$bvToast.toast(` Price updated successfully`, {
        title: `Price Update`,
        toaster: toaster,
        variant: `success`,
        appendToast: append
      })
    },
    addShippingPartner() {
      this.showAddPartner = true
    },
    newPartner() {
      console.log("new Partner is called!!")
      if (this.shippingpartners != '' && this.trackingURL != '') {
        this.addNewShippingPartner({ shippingpartners: this.shippingpartners, trackingURL: this.trackingURL })
        this.shippingpartners = this.trackingURL = ''
        setTimeout(() => {
          this.showAddPartner = false
        }, 500)

      }
    },
    onClickShippingPrice(clickingFrom, priceObj) {
      if (clickingFrom == 'newShippingPrice') {
        this.shippingPriceTitle = "New Shipping Price";
        this.shippingPriceObj = {
          itemType: '',
          weightRangeMin: null,
          weightRangeMax: null,
          price: null,
          country: ''
        },

          this.isCreatingNewPrice = true;
      }
      else {
        this.shippingPriceTitle = "Edit Shipping Price";
        this.isCreatingNewPrice = false;
        this.shippingPriceObj = priceObj;

      }
      this.showshippingPrice = true;
    },
    async addOrUpdateShippingPrice() {
      const comp = this;
      if (comp.shippingPriceObj.itemType != '' && comp.shippingPriceObj.weightRangeMin > -1 && comp.shippingPriceObj.weightRangeMax > 0 && comp.shippingPriceObj.price > 0 && comp.shippingPriceObj.country != '') {
        console.log("addOrUpdateShippingPrice() is called")
        if (comp.isCreatingNewPrice) {
          comp.shippingPriceObj["shippingpartnerId"] = comp.shippingPartnerId;
          comp.addNewShippingPrice(comp.shippingPriceObj)
        }
        else {
          comp.shippingPriceObj['country'] = comp.country
          comp.priceUpdate(this.shippingPriceObj)
        }
        this.showshippingPrice = false;
      }

    },
    getCountryName(countryName){
      return isoCountries.getName(countryName, "en")
    },
    validateWeightRange() {
  const minWeight = parseFloat(this.shippingPriceObj.weightRangeMin);
  const maxWeight = parseFloat(this.shippingPriceObj.weightRangeMax);
  console.log('minWeight:', minWeight, 'maxWeight:', maxWeight);

  if (minWeight >= maxWeight) {
    this.weightRangeError = true; 
  } else {
    this.weightRangeError = false; 
  }
},



  },
  // async mounted() {
    
  // },
  directives: {
    Ripple
  },
  data() {
    return {
      //selectedPartnerPrices:[],
      tableColumns: [
        { key: 'itemType' },
        { key: 'weightRangeMin' },
        { key: 'weightRangeMax' },
        { key: 'price' },
        { key: 'country' },
        { key: 'actions' }
      ],
      showAddPartner: false,
      shippingpartners: '',
      trackingURL: '',
      selectedItem: "shippingPartners",
      selectPartnerId: null,
      shippingPriceObj: {
        itemType: '',
        weightRangeMin: 0,
        weightRangeMax: 0,
        price: 0,
        country: ''
      },
      shippingPriceTitle: "",
      showshippingPrice: false,
      shippingPartnerId: null,
      isCreatingNewPrice: false,
      weightRangeError: false,



    }
  },

  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BPopover,
    vSelect,
    BCardCode,
    BCardText
  },
}
</script>
<style>
.prepend-box>.form-control {
  border: 1px solid #c9c9c9;
}

/* .modal-header .close {
  display:none;
} */
.modal .modal-footer>.btn {
  /* margin-left: 0.75rem; */
  display: none;
}

.modal .modal-footer {
  padding: 0;
  border: none;
}

.errmsg{
color: red;
font-style: italic;
}

.form-outline{
  width: 6rem;
}
</style>
